import numeral from 'numeral';
import type Echo from 'laravel-echo';
import getFullName from '@/storychief/utils/getFullName';

type User = {
  id: number;
  hashed_id?: string;
  role: number;
  email: string;
  firstname: string;
  lastname: string;
  fullname: string;
  phone: string;
  profile_picture: string;
  email_encoded: string;
  accounts_count: number;
};

type Account = {
  id: number;
  parent_id: number;
  name: string;
  display_name: string;
  slug: string;
  logo: string;
  shareDomain: string;
  referralDomain: string;
  ownerId: number;
  verified: boolean;
  languages: object[];
  white_label_partner: { id: string };
  default_story_brief: object;
  agency_logo: string;
  trial: boolean;
  freemium: boolean;
};

declare global {
  interface Window {
    Echo?: Echo;
    Intercom?: Function;
    StoryChief: {
      account: Account;
      appLogoUri: string;
      appVersion: string;
      asset_url: string;
      app_url: string;
      apiBasePath: string;
      camo_url: string;
      help_url: string;
      academy_url: string;
      csrfToken: string;
      dashboardBasePath: string;
      editor_settings: {
        controls: {
          align_float: boolean;
          display_wide: boolean;
          display_full: boolean;
          disallow_line_breaks: boolean;
        };
      };
      environment: 'dev' | 'test' | 'testing' | 'production';
      graphqlUrl: string;
      isSupportedBrowser: boolean;
      settings: {
        dateFormat: string;
        firstDayWeek: '0' | '1';
        numberFormat: string;
        timezone: string;
      };
      services: {
        cloudfront: {
          url: string;
          outputUrl: string;
        };
        pusher: {
          key: string;
        };
        stripe: {
          key: string;
        };
        googlemapsembed: {
          key: string;
        };
        recaptcha: {
          key: string;
        };
      };
      policies: {
        [key: string]: string;
      };
      publicBlogUrl: string;
      user: User;
      storyCommentsEndpoint: string;
    };
  }
}

const timezone = window.StoryChief.settings.timezone;
const appLogoUri = window.StoryChief.appLogoUri;
const firstDayWeek = parseInt(window.StoryChief.settings.firstDayWeek, 10) as 0 | 1;
const numberFormat = window.StoryChief.settings.numberFormat;
const csrfToken = window.StoryChief.csrfToken;
const cloudfront = window.StoryChief.services.cloudfront;
const pusher = window.StoryChief.services.pusher;
const stripe = window.StoryChief.services.stripe;
const googlemapsembed = window.StoryChief.services.googlemapsembed;
const policies = window.StoryChief.policies;
const user = window.StoryChief.user;
const account = window.StoryChief.account;
const hasOwnProperty = Object.prototype.hasOwnProperty;
const assetUrl = window.StoryChief.asset_url;
const appBaseUrl = window.StoryChief.app_url;
const apiBasePath = window.StoryChief.apiBasePath;
const publicBlogUrl = window.StoryChief.publicBlogUrl;
const dashboardBasePath = window.StoryChief.dashboardBasePath;
const storyCommentsEndpoint = window.StoryChief.storyCommentsEndpoint;
const appVersion = window.StoryChief.appVersion;
const environment = window.StoryChief.environment;
const isSupportedBrowser = window.StoryChief.isSupportedBrowser;
const graphqlUrl = window.StoryChief.graphqlUrl;
const camoUrl = window.StoryChief.camo_url;
const editorSettings = window.StoryChief.editor_settings;

numeral.register('locale', 'custom1', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: '€',
  },
  ordinal: numeral.locales.en.ordinal,
});
numeral.register('locale', 'custom2', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: '€',
  },
  ordinal: numeral.locales.en.ordinal,
});
numeral.register('locale', 'custom3', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: '€',
  },
  ordinal: numeral.locales.en.ordinal,
});

const StoryChief = {
  appBaseUrl,
  apiBasePath,
  dashboardBasePath,
  assetUrl,
  publicBlogUrl,
  storyCommentsEndpoint,
  graphqlUrl,
  camoUrl,
  editorSettings,
  get csrfToken() {
    return csrfToken;
  },
  get numeral() {
    numeral.locale(numberFormat);
    return numeral;
  },
  get firstDayWeek() {
    return firstDayWeek;
  },
  get timezone() {
    return timezone;
  },
  get cloudfront() {
    return cloudfront;
  },
  get pusher() {
    return pusher;
  },
  get stripe() {
    return stripe;
  },
  get googlemapsembed() {
    return googlemapsembed;
  },
  get user() {
    if (user) {
      if (!user.profile_picture || user.profile_picture === '') {
        user.profile_picture = StoryChief.asset('images/placeholders/profile.png');
      }
      user.fullname = getFullName(user);
    }
    return user;
  },
  get account() {
    return account;
  },

  get logo() {
    return appLogoUri;
  },

  get shareDomain() {
    return account.shareDomain;
  },

  get appVersion() {
    return appVersion;
  },

  get environment() {
    return environment;
  },

  get shouldCaptureErrorsInSentry() {
    return environment !== 'dev' && isSupportedBrowser;
  },

  accessRight(name: string): boolean {
    if (hasOwnProperty.call(policies, name)) return !!policies[name];
    return false;
  },
  asset(path: string): string {
    return new URL(path, assetUrl).href;
  },
  startIntercomMessage(message = '', subject = '') {
    if (window.Intercom) {
      window.Intercom('showNewMessage', message); // eslint-disable-line new-cap
    } else {
      const mailTo =
        account?.trial || account?.freemium ? 'hey@storychief.io' : 'success@storychief.io';
      window.location.href = `mailto:${mailTo}?subject=${subject}&body=${message}`;
    }
  },
  getProfilePicture() {
    if (user) {
      return user.profile_picture;
    }

    // Return default image for users in preview mode
    return this.asset('images/placeholders/profile.png');
  },
  getDefaultProfilePicture() {
    return this.asset('images/placeholders/profile.png');
  },
};

export default StoryChief;
